/* src/App.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5dc; /* Light brown background */
}

nav {
  background: #333;
  color: #fff;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav h1 {
  margin: 0;
}

nav ul {
  list-style: none;
  display: flex;
  gap: 1rem;
}

nav ul li {
  margin: 0;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
}

.front-cover,
.book {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  height: calc(100vh - 4rem); /* Adjust height to fit within viewport minus navbar */
}

.cover-container {
  display: flex;
  flex-direction: column;
  border: 2px solid #8b4513; /* Dark brown border */
  background-color: #a0522d; /* Dark brown background for the book */
  position: relative;
  width: 400px; /* Fixed width */
  height: 60vh; /* Adjust height to make it look like a book cover */
  justify-content: flex-start;
  align-items: center;
  padding: 1rem; /* Add padding to ensure the image fits well inside */
}

.book-container {
  display: flex;
  border: 2px solid #8b4513; /* Dark brown border */
  background-color: #a0522d; /* Dark brown background for the book */
  position: relative;
  width: 800px; /* Fixed width */
  height: 60vh; /* Adjust height to make it look like a book */
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Hide overflow to ensure animation looks smooth */
}

.page {
  width: 48%;
  margin: 0 1%;
  transition: transform 0.6s ease-in-out;
  backface-visibility: hidden;
  transform-origin: left;
}

.page img {
  width: 100%;
  height: auto;
}

.page-separator {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 2px;
  background-color: #333;
}

button {
  margin: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  background-color: #333;
  color: #fff;
  border: none;
}

button:disabled {
  background-color: #aaa;
}

.arrow-button {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #333;
}

.arrow-button:disabled {
  color: #aaa;
}

.cover-title {
  font-size: 3rem;
  color: #fff;
  margin-top: 1rem; /* Add space from the top */
  text-align: center; /* Center the text */
}

.cover-image {
  margin-top: 1rem; /* Add space from the title */
  max-width: 100%;
  height: auto;
  flex-shrink: 0;
}

/* Animation for page turn */
@keyframes turnPage {
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(-180deg);
  }
}

@keyframes turnPageBack {
  0% {
    transform: rotateY(-180deg);
  }
  100% {
    transform: rotateY(0);
  }
}

.page-turn {
  animation: turnPage 0.6s forwards;
}

.page-turn-back {
  animation: turnPageBack 0.6s forwards;
}
